import React, { useEffect, useState } from 'react';
import { ReactComponent as SuccessIcon } from '../../assets/images/payment/payment-success.svg';
import { ReactComponent as FailIcon } from '../../assets/images/payment/payment-fail.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import queryString from 'query-string';
import { confirmOrderPayment, getPaymentStatus } from '../../api/checkoutAPIs';
import { useNavigate } from 'react-router-dom';
import { clearMiniCartModal } from '../../redux/addToCart';

export default function PaymentResult() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [paymentStatus, setPaymentStatus] = useState('success');
    const [orderId, setOrderId] = useState('');
    function determinePaymentMethod(method) {
        switch (method) {
            case '15':
            case 15:
                return 2;
            case '16':
            case 16:
                return 1;
            default:
                return 2;
        }
    }

    async function determinePaymentStatusHandler() {
        dispatch(showLoader());
        if (window?.location?.search === '') return;
        const parsed = queryString.parse(window.location.search);
        console.log(parsed, 'parsed');
        parsed.orderId && setOrderId(parsed.orderId);
        try {
            await confirmOrderPayment(parsed.id || '');
            setPaymentStatus('success');
            dispatch(clearMiniCartModal());
            setTimeout(() => {
                navigate(`/profile/orders/${parsed.orderId}`);
            }, 5000);
        } catch (error) {
            console.log(error);
            setPaymentStatus('fail');
        }
        dispatch(hideLoader());
    }

    async function getPaymentStatusHandler() {
        dispatch(showLoader());
        if (window?.location?.search === '') return;
        console.log(window.location.search);
        const parsed = queryString.parse(window.location.search);
        const successPattern = /^(000\.000\.|000\.100\.1|000\.[36])/;
        const manualPattern = /^(000\.400\.0[^3]|000\.400\.100)/;
        console.log(parsed);
        const formData = {
            orderId: 0,
            CheckoutId: parsed.id || '',
            PaymentType: determinePaymentMethod(parsed.paymentMethodId) || '',
        };
        try {
            const { data } = await getPaymentStatus(formData);
            console.log(data?.data?.paymentStatus);
            const match1 = successPattern.test(data?.data?.paymentStatus);
            const match2 = manualPattern.test(data?.data?.paymentStatus);
            if (match1 || match2) {
                await confirmOrderPayment(parsed.id || '');
                setPaymentStatus('success');
            } else {
                setPaymentStatus('fail');
            }
        } catch (error) {
            console.log(error);
            setPaymentStatus('fail');
        }
        dispatch(hideLoader());
    }

    useEffect(() => {
        // getPaymentStatusHandler();
        determinePaymentStatusHandler();
    }, []);

    return (
        <section className="container_bx payment-section mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="payment-result__container text-center">
                            {paymentStatus === 'success' ? (
                                <>
                                    <div className="payment-result__icon">
                                        <SuccessIcon />
                                    </div>
                                    <div className="payment-result">
                                        <h2 className="payment-result__text">
                                            {t(`successfulPayment`)}
                                        </h2>
                                        <button
                                            className="main-btn mt-4"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate(`/profile/orders/${orderId}`);
                                            }}>
                                            {t(`viewOrder`)}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="payment-result__icon">
                                        <FailIcon />
                                    </div>
                                    <div className="payment-result">
                                        <h2 className="payment-result__text">{t(`failPayment`)}</h2>
                                        <button
                                            className="main-btn mt-4"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/cart/couponId=');
                                            }}>
                                            {t(`viewCart`)}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
