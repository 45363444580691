import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { applyCustomCoupon } from '../../api/shopingCartAPIs';
import { useLocation, useParams } from 'react-router-dom';
import sweetAlert from '../../helpers/sweetAlert';
import { useDispatch } from 'react-redux';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';

const PriceCard = ({
    prices = {},
    setPrices = () => {},
    couponCode = '',
    setCouponCode = () => {},
    forceRerender = () => {},
}) => {
    const { t } = useTranslation();
    const { couponId } = useParams();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const [customLoading, setCustomLoading] = useState(false);

    async function applyCouponHandler(couponCode = '') {
        if (!couponCode) return;
        setCustomLoading(true);
        dispatch(showMiniLoader());
        try {
            const { data } = await applyCustomCoupon({
                discountCouponCode: couponCode,
            });
            console.log(data);
            setPrices(data?.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.statusMessage) {
                sweetAlert.error(error.response.data.statusMessage);
            }
        }
        setCustomLoading(false);
        setTimeout(() => {
            dispatch(hideMiniLoader());
        }, 200);
    }

    useEffect(() => {
        if (pathname.includes('checkout')) {
            applyCouponHandler(couponCode);
        }

        return () => {
            setCustomLoading(false);
            setCouponCode('');
        };
    }, [pathname]);

    useEffect(() => {
        const coupon = couponId?.split('=')?.[1];
        if (coupon) {
            setCouponCode(coupon);
            applyCouponHandler(coupon);
        }

        return () => {
            setCustomLoading(false);
            setCouponCode('');
        };
    }, [couponId]);

    return (
        <div className="price-card-postion">
            <h2>{t('orderSummary')}</h2>
            <div className="price-card card-border">
                <p
                    className={`eligible-text ${
                        prices?.total - prices?.shipping >= prices?.exceedValue ? 'eligible' : ''
                    }`}>
                    <span className="flex align-items-center">
                        {prices?.total - prices?.shipping >= prices?.exceedValue
                            ? `${t('eligibleForFreeShipping')} ${parseFloat(
                                  prices?.exceedValue || 0,
                              )?.toFixed(2)}`
                            : `${t('awayFromFreeShipping')} ${parseFloat(
                                  prices?.exceedValue || 0,
                              )?.toFixed(2)} ${t('toGetFreeShipping')}`}
                    </span>
                </p>
                <p className="flex justify-content-between align-items-center">
                    <span>{t('subtotal')}</span>
                    <span>
                        {parseFloat(prices?.subTotal || 0)?.toFixed(2)} {t(`sar`)}
                    </span>
                </p>
                <p className="flex justify-content-between align-items-center">
                    <span>{t('shipping')}</span>

                    {prices?.total - prices?.shipping >= prices?.exceedValue ? (
                        <span style={{ color: '#07c564' }}>{t('free')}</span>
                    ) : (
                        <span>
                            {parseFloat(prices?.shipping || 0)?.toFixed(2)} {t(`sar`)}
                        </span>
                    )}
                </p>
                <p className="flex justify-content-between align-items-center">
                    <span> {t('tax')}</span>
                    <span>
                        {parseFloat(prices?.tax || 0)?.toFixed(2)} {t(`sar`)}
                    </span>
                </p>

                {couponCode && prices?.codeFee ? (
                    <p className="flex justify-content-between align-items-center">
                        <span>{t('Promocode')}</span>
                        <span style={{ color: '#07C564' }}>
                            -{parseFloat(prices?.codeFee || 0)?.toFixed(2)} {t(`sar`)}
                        </span>
                    </p>
                ) : null}
                <div
                    className="flex justify-content-between align-items-center"
                    style={{ marginBottom: '0px' }}>
                    <input
                        type="text"
                        placeholder={t('couponCode')}
                        value={couponCode}
                        maxLength={5}
                        disabled={customLoading || prices?.codeFee}
                        onChange={(e) => {
                            setCouponCode(e.target.value);
                        }}
                    />
                    <button
                        disabled={customLoading || (couponCode && prices?.codeFee)}
                        onClick={() => applyCouponHandler(couponCode)}>
                        {t('apply')}
                    </button>

                    {/* x for removing coupon */}
                    {(couponCode && prices?.codeFee && (
                        <button
                            onClick={() => {
                                forceRerender();
                                setTimeout(() => {
                                    setCouponCode('');
                                }, 200);
                            }}>
                            x
                        </button>
                    )) ||
                        null}
                </div>

                {(couponCode && prices?.codeFee && (
                    <p
                        className="code_applied"
                        style={{
                            color: '#07C564',
                            fontSize: '13px',
                            marginTop: '0px',
                            paddingTop: '0px',
                        }}>
                        {t(`Code applied`)}
                    </p>
                )) ||
                    null}

                <p className="flex justify-content-between align-items-center">
                    <span>{t('total')}</span>
                    <span>
                        {parseFloat(prices?.total || 0)?.toFixed(2)} {t(`sar`)}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default PriceCard;
