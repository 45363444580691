import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../LoadingButton/LoadingButton';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';
import { flagProductReviewAPI } from '../../api/productAPIs';
import sweetAlert from '../../helpers/sweetAlert';

export default function FlagProductReview({
    toggleReviewModal,
    setToggleReviewModal = () => {},
    reviewId = '',
    setReviewId = () => {},
    forceRerender = () => {},
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleReset = () => {
        // other logic
        setToggleReviewModal(false);
        setReviewId('');
        dispatch(hideMiniLoader());
    };

    async function flagProductReviewHandler() {
        // API call
        console.log(reviewId);
        if (!reviewId) return;

        dispatch(showMiniLoader());

        try {
            await flagProductReviewAPI({ id: reviewId });
            sweetAlert.success(t(`review-flagged-successfully`));
            handleReset();
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            console.log(error);
            sweetAlert.error(error.response.data.statusMessage ?? t(`something_went_wrong`));
        }

        dispatch(hideMiniLoader());
    }

    return (
        <>
            <Dialog
                className="flag-review-product-modal"
                // header={""}
                visible={toggleReviewModal}
                onHide={handleReset}
                style={{ width: '90%', maxWidth: '450px' }}
                draggable={false}
                resizable={false}>
                <div className="confirm-popup-body">
                    <div className="form-bx">
                        <div className="flex flex-column">
                            <div className="review-text">
                                <h1 className="text-center flag-text">{t(`flag-review`)}</h1>
                                <p className="flag-description">{t(`flag-review-text`)}</p>
                            </div>
                            <div className="report-review__actions flex align-items-center justify-content-center gap-4">
                                <Button
                                    label={t('cancel')}
                                    className="cancel-btn"
                                    onClick={handleReset}
                                />
                                <LoadingButton
                                    btnText={t(`submit`)}
                                    btnClick={flagProductReviewHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
