import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { useForm } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { deleteCardAPI, updateCardAPI, addCardAPI, getCardsAPI } from '../../api/checkoutAPIs';
import { Controller } from 'react-hook-form';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import { ReactComponent as Plus } from '../../assets/images/plus.svg';
import getFormErrorMessage from '../../components/formError/formError';
import sweetAlert from '../../helpers/sweetAlert';
import AddDebitCardModal from '../../components/Modal/AddDebitCardModal';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';
import PaymentCardsChange from '../../components/Modal/PaymentCardsChange';
import CheckoutCreditCard from '../../components/Cart/CheckoutCreditCard';

const PaymentList = ({
    list = [],
    paymentMethodId = '',
    setPaymentMethodId = () => {},
    visaCardId = '',
    setVisaCardId = () => {},
    cvv = '',
    setCvv = () => {},
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [cards, setCards] = useState([]);
    const [addCard, setAddCard] = useState(false);
    const [editCard, setEditCard] = useState(false);
    const [changeSelectedCard, setChangeSelectedCard] = useState(false);
    const [showCVVModal, setShowCVVModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});

    const [pageSize] = useState(10);
    const [page, setPage] = useState(0);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            id: '',
            cardNumber: '',
            holderName: '',
            expiryDate: '',
            isSaved: false,
        },
    });

    async function getCards(selectedCardId) {
        //   handleReset();
        const cardsData = {
            expired: false,
            paging: {
                PageNumber: page,
                PageSize: pageSize,
            },
        };
        try {
            const { data } = await getCardsAPI(cardsData);
            setCards(data?.data);
            if (selectedCardId) {
                setSelectedCard(data?.data?.list?.find((card) => card?.id == selectedCardId) || {});
                setVisaCardId(selectedCardId);
            } else {
                setVisaCardId(data?.data?.list?.find((card) => card?.default)?.id || '');
                setSelectedCard(data?.data?.list?.find((card) => card?.default) || {});
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function addNewCard(values) {
        if (values?.id) delete values?.id;
        const cardData = {
            ...values,
            expiryDate: values?.expiryDate?.toLocaleDateString('en-GB', {
                month: '2-digit',
                year: '2-digit',
            }),
        };
        dispatch(showMiniLoader());
        try {
            const { data } = await addCardAPI(cardData);
            console.log(data);
            sweetAlert.success(t('success'));
            handleReset();
            setTimeout(() => {
                getCards(data?.data);
            }, 200);
        } catch (error) {
            sweetAlert.error(
                error?.response?.data
                    ? error?.response?.data?.statusMessage || t('error')
                    : t('error'),
            );
        }
        dispatch(hideMiniLoader());
    }

    const handleReset = () => {
        reset({
            id: '',
            cardNumber: '',
            holderName: '',
            expiryDate: '',
            default: false,
        });
        setAddCard(false);
        setChangeSelectedCard(false);
        setEditCard(false);
    };

    useEffect(() => {
        getCards();
    }, [page]);

    return (
        <>
            <h2>{t('paymentMethods')}</h2>
            <div className="payment-list card-border">
                <ul className="grid">
                    {list?.map((payment, index) => (
                        <li key={index} className="col-12">
                            <div className="flex justify-content-between">
                                <span className="w-full">
                                    <span>
                                        <RadioButton
                                            name="payment"
                                            className="mx-2"
                                            id={payment.id}
                                            value={payment.id}
                                            checked={paymentMethodId == payment.id}
                                            disabled={payment.soon}
                                            onChange={(e) => {
                                                setPaymentMethodId(e.value);
                                                setCvv('');
                                            }}
                                        />
                                        <label htmlFor={payment.id}>{payment.title}</label>
                                    </span>

                                    {payment.type == 2 && paymentMethodId == payment.id && (
                                        <ul className="w-full hidden">
                                            {selectedCard?.id ? (
                                                <li
                                                    key={index}
                                                    className="flex align-items-center justify-content-between w-full">
                                                    <CheckoutCreditCard card={selectedCard} />
                                                    {/* {console.log(selectedCard, 'selected card')} */}
                                                </li>
                                            ) : null}

                                            <li
                                                className={`flex align-items-center ${
                                                    selectedCard?.id ? `pt-0` : ``
                                                }`}>
                                                <button
                                                    className="outline-btn font-medium"
                                                    onClick={() => setAddCard(true)}>
                                                    <Plus /> {t('addCard')}
                                                </button>
                                                {cards?.list?.length > 0 && (
                                                    <button
                                                        className="outline-btn"
                                                        style={{ color: '#999' }}
                                                        onClick={() => setChangeSelectedCard(true)}>
                                                        {t('changeSelectedCard')}
                                                    </button>
                                                )}
                                            </li>
                                        </ul>
                                    )}
                                </span>
                                <img
                                    src={payment.icon}
                                    alt={payment.name}
                                    className="mx-2"
                                    height={20}
                                    width={35}
                                    style={{ objectFit: 'contain' }}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <AddDebitCardModal
                showModal={addCard}
                setShowModal={setAddCard}
                control={control}
                handleSubmit={handleSubmit}
                addNewCard={addNewCard}
                errors={errors}
                watch={watch}
                isValid={isValid}
                setValue={setValue}
                reset={reset}
            />

            <PaymentCardsChange
                isVisible={changeSelectedCard}
                setIsVisible={setChangeSelectedCard}
                visaCardId={visaCardId}
                setVisaCardId={setVisaCardId}
                cardsList={cards?.list}
                cardsPaging={cards?.paging}
                setSelectedCard={setSelectedCard}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
            />

            <Dialog
                header={
                    <Back
                        className="back-btn"
                        onClick={() => {
                            setCvv('');
                            setShowCVVModal(false);
                        }}
                    />
                }
                visible={showCVVModal}
                style={{ width: '20%' }}
                onHide={() => {
                    setCvv('');
                    setShowCVVModal(false);
                }}>
                <div className="p-fluid">
                    <label className="block mb-2" htmlFor="cvv">
                        {t('enterCVV')}
                    </label>
                    <InputText
                        className="w-full mb-4"
                        value={cvv}
                        keyfilter="pnum"
                        onChange={(e) => setCvv(e.target.value)}
                        maxLength={3}
                        minLength={3}
                        autoFocus
                    />
                    <button
                        className="main-btn w-full"
                        onClick={() => {
                            if (cvv?.length != 3) {
                                sweetAlert.error(t('cvvValidation'));
                                return;
                            }
                            setShowCVVModal(false);
                            sweetAlert.success(t('success'));
                        }}>
                        {t('submit')}
                    </button>
                </div>
            </Dialog>
        </>
    );
};

export default PaymentList;
