import { createAsyncThunk } from '@reduxjs/toolkit';
import { baseInstance } from './baseInstance';

export const getCompareList = createAsyncThunk('/Product/MyCompare', async (body) => {
    try {
        const res = await baseInstance.post(`/Product/MyCompare`, body);
        return res.data;
    } catch (error) {
        console.log(error);
    }
});

export const getAddToCompareList = createAsyncThunk('/Product/AddProductToCompareList',
    async (ids) => {
        console.log("ids", ids)
        // debugger;
        try {
            const res = await baseInstance.post(`/Product/AddProductToCompareList`, { ids: [ids.productId] });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const getRemoveFromCompareList = createAsyncThunk(
    '/Product/RemoveProductFromCompareList',
    async (ids) => {
        try {
            const res = await baseInstance.post(`/Product/RemoveProductFromCompareList`, {
                ids: [ids.productId],

            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    },
);
