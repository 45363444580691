import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import search from '../../assets/images/search_icon_white.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';
import { getSearchSuggestionsByKeyword } from '../../api/productAPIs';
import { ClockLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { HiOutlineExternalLink } from 'react-icons/hi';

export default function MainSearch() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const isLoading = useSelector((state) => state.mainLoader.isMiniLoader);

    const [searchValue, setSearchValue] = useState('');
    const [searchList, setSearchList] = useState([]);

    async function handelSearch(searchValueText) {
        // if (searchValue) navigate(`/search/${searchValue}`);
        if (!searchValueText) return;
        dispatch(showMiniLoader());

        try {
            const { data } = await getSearchSuggestionsByKeyword(searchValueText);
            console.log(data, 'search data');
            setSearchList(data?.data);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideMiniLoader());
    }

    return (
        <>
            <div className="relative">
                <span className="p-input-icon-right">
                    {isLoading ? (
                        <span className="search-icon">
                            <ClockLoader
                                color={'#fff'}
                                loading={isLoading}
                                // aria-label="Loading Spinner"
                                data-testid="loader"
                                size={20}
                            />
                        </span>
                    ) : (
                        <span className="search-icon">
                            <img src={search} alt="pic" onClick={handelSearch} />
                        </span>
                    )}
                    <InputText
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                            handelSearch(e.target.value);
                        }}
                        onKeyDown={(e) => e.key === 'Enter' && handelSearch()}
                    />
                    {searchValue && (
                        <span
                            className="reset-search"
                            onClick={() => {
                                setSearchValue('');
                                setSearchList([]);
                                navigate('/');
                            }}>
                            x
                        </span>
                    )}
                </span>
                {searchValue && (
                    <div className="search-result__wrapper">
                        <div className="search-result__body">
                            <div className="content">
                                <div
                                    className="keyword search-result__item relative"
                                    onClick={() => {
                                        navigate(`/search/${searchValue}?keyword=${searchValue}`);
                                        setSearchList([]);
                                        setSearchValue('');
                                    }}>
                                    <h3>{t(`viewAllFor`)}</h3>
                                    <p className="mx-2">{searchValue}</p>
                                    <div className="search-result__icon">
                                        <HiOutlineExternalLink className="cursor-pointer" />
                                    </div>
                                </div>
                                {searchList.length > 0 &&
                                    searchList.map((item, index) => (
                                        <div key={index} className="search-result__item">
                                            <div
                                                className="search-result__item__content relative"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (item?.type === 0) {
                                                        navigate(
                                                            `/search/${searchValue}?keyword=${item?.name}`,
                                                        );
                                                        setSearchList([]);
                                                        setSearchValue('');
                                                    } else if (item?.type === 1) {
                                                        navigate(
                                                            `/search/${searchValue}?name=${
                                                                item?.name
                                                            }&category=${
                                                                item?.subId ? item?.subId : item?.id
                                                            }${
                                                                item?.subId
                                                                    ? `&ParentCategory=${item?.id}`
                                                                    : ''
                                                            }`,
                                                        );
                                                        setSearchList([]);
                                                        setSearchValue('');
                                                    } else if (item?.type === 2) {
                                                        navigate(
                                                            `/search/${searchValue}?name=${item?.name}&brand=${item?.id}`,
                                                        );
                                                        setSearchList([]);
                                                        setSearchValue('');
                                                    }
                                                }}>
                                                <h3>{item.name}</h3>
                                                <div className="search-result__icon">
                                                    <HiOutlineExternalLink
                                                        className="cursor-pointer"
                                                        onClick={() =>
                                                            navigate(`/search/${searchValue}`)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
