import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from '../../api/commonAPIs';
import { showLoader, hideLoader } from '../../redux/loaderSlice';
import { ReactComponent as Verified } from '../../assets/images/verified.svg';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import { deleteAPI } from '../../api/authAPIs';
import {
    editCustomerInfo,
    sendVerificationEmail,
    editPhoneNumber,
    getCustomerInfo,
    verifyPhone,
} from '../../api/CustomerAPIs';
import { changePasswordAPI, otpAPI } from '../../api/authAPIs';
import { loginAction, logoutAction } from '../../redux/authSlice';
import { clearMiniCartModal } from '../../redux/addToCart';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { authTypes } from '../../utils/constants';
import sweetAlert from '../../helpers/sweetAlert';
import getFormErrorMessage from '../../components/formError/formError';
import countryOptionTemplate from '../../components/countryOptions/countryOptions';
import selectedCountryTemplate from '../../components/selectedCountry/selectedCountry';
import OTPInput from 'react-otp-input';
import Countdown from 'react-countdown';

const MyProfile = () => {
    const navigate = useNavigate();
    const storedUserData = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [userData, setUserData] = useState({});
    const [countriesList, setCountriesList] = useState([]);
    const [showEditButton, setShowEditButton] = useState(false);
    const [showEmailChange, setShowEmailChange] = useState(false);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [showPhoneChange, setShowPhoneChange] = useState(false);
    const [togglePhoneOtpModal, setTogglePhoneOtpModal] = useState(false);
    const [toggleEmailOtpModal, setToggleEmailOtpModal] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const [countdown, setCountdown] = useState(30);

    const {
        control,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        reset,
        resetField,
        setValue,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstName: storedUserData?.firstName || userData.firstName || '',
            lastName: storedUserData?.lastName || userData.lastName || '',
            email: storedUserData?.email || userData.email || '',
            phone: storedUserData?.phone || userData.phoneNumber || '',
            country: storedUserData?.country || userData.country || '',
            otp: '',
            key: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
    });

    const watchAllFields = watch();

    async function getUserData() {
        dispatch(showLoader());

        try {
            const { data } = await getCustomerInfo();
            const dataCountry = countriesList?.find(
                (country) => country?.id == data?.data?.country?.id,
            );
            console.log('dataCountry', dataCountry);
            setUserData(data?.data);
            reset({
                firstName: data?.data?.firstName || '',
                lastName: data?.data?.lastName || '',
                email: data?.data?.email || '',
                phone: data?.data?.phoneNumber || '',
                country:
                    {
                        id: dataCountry?.id || storedUserData?.country?.id,
                        name: dataCountry?.phoneCode || storedUserData?.country?.phoneCode,
                        code: dataCountry?.twoLetterCode || storedUserData?.country?.twoLetterCode,
                        hintText: dataCountry?.hintText || storedUserData?.country?.hintText,
                        phoneLength:
                            dataCountry?.phoneLength || storedUserData?.country?.phoneLength,
                    } || '',
            });
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function changeGeneralInfo() {
        dispatch(showLoader());

        try {
            await editCustomerInfo({
                firstName: watchAllFields?.firstName,
                lastName: watchAllFields?.lastName,
            });
            reset({ firstName: watchAllFields?.firstName, lastName: watchAllFields?.lastName });
            dispatch(
                loginAction({
                    ...storedUserData,
                    firstName: watchAllFields?.firstName,
                    lastName: watchAllFields?.lastName,
                }),
            );
            sweetAlert.success(t('success'));
        } catch (error) {
            sweetAlert.error(t('error'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function changeEmail() {
        setShowEmailChange(true);

        // try {
        //     dispatch(showLoader());
        //     await editEmailAddress({ email: formData?.email });
        //     reset({ email: formData?.email });
        //     dispatch(loginAction({ ...storedUserData, email: formData?.email }));
        //     sweetAlert.success(t('success'));
        //     dispatch(hideLoader());
        //     setShowEmailChange(false);
        // }
        // catch (error) {
        //     sweetAlert.error(t('error'));
        //     console.log(error);
        //     dispatch(hideLoader());
        // }
    }

    async function changePasswordHandler() {
        dispatch(showLoader());

        const formData = {
            newPassword: watchAllFields?.newPassword,
            confirmPassword: watchAllFields?.confirmPassword,
        };

        try {
            await changePasswordAPI(
                userData.hasPassword
                    ? { ...formData, oldPassword: watchAllFields?.oldPassword }
                    : formData,
            );
            sweetAlert.success(t('success'));
            dispatch(logoutAction());
            dispatch(clearMiniCartModal());
            navigate('/');
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
        }

        dispatch(hideLoader());
    }

    async function getCountriesList() {
        dispatch(showLoader());

        try {
            const { data } = await getCountries(true);
            setCountriesList(data?.data);
            if (!userData)
                setValue('country', {
                    id: data?.data[0]?.id,
                    name: data?.data[0]?.phoneCode,
                    code: data?.data[0]?.twoLetterCode,
                    hintText: data?.data[0]?.hintText,
                    phoneLength: data?.data[0]?.phoneLength,
                });
        } catch (error) {
            sweetAlert.error(t('error'));
        }

        dispatch(hideLoader());
    }

    async function changePhoneNumber() {
        dispatch(showLoader());

        try {
            const { data } = await editPhoneNumber({
                phone: watchAllFields?.phone,
                countryId: watchAllFields?.country?.id,
            });
            if (data?.data?.key) {
                setValue('key', data?.data?.key);
                setTogglePhoneOtpModal(true);
                setShowPhoneChange(false);
                dispatch(hideLoader());
                sweetAlert.success(t('success'));
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
        }

        dispatch(hideLoader());
    }

    async function handlePhoneOTPSubmit() {
        dispatch(showLoader());

        const formData = {
            countryId: watchAllFields?.country?.id,
            key: watchAllFields?.key,
            otp: watchAllFields?.otp,
        };

        try {
            const { data } = await verifyPhone(formData);
            if (data?.statusCode === 200) {
                sweetAlert.success(t('phone_number_verified'));
                getUserData();
                dispatch(
                    loginAction({
                        ...storedUserData,
                        phone: watchAllFields?.phone,
                        country: countriesList?.find(
                            (country) => country?.id === watchAllFields?.country?.id,
                        ),
                    }),
                );
                handleReset();
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            resetField('otp');
        }

        dispatch(hideLoader());
    }

    async function handlePhoneResendOtp() {
        dispatch(showLoader());

        try {
            const { data } = await editPhoneNumber({
                phone: watchAllFields?.phone,
                countryId: watchAllFields?.country?.id,
            });
            if (data?.data?.key) {
                setValue('key', data?.data?.key);
                setValue('otp', '');
                sweetAlert.success(t('phone_number_otp_send'));
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
            setIsResendDisabled(true); // Disable the button
            setCountdown(30); // Reset countdown
            startCountdown(); // Start countdown again
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function verifyEmailHandler() {
        dispatch(showLoader());

        try {
            const { data } = await sendVerificationEmail({ email: userData?.email });
            sweetAlert.success(t('otp_sent'));
            if (data?.data?.key) {
                setValue('key', data?.data?.key);
                setToggleEmailOtpModal(true);
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
        }

        dispatch(hideLoader());
    }

    async function handleEmailOTPSubmit() {
        dispatch(showLoader());

        const formData = {
            key: watchAllFields?.key,
            otp: watchAllFields?.otp,
            authType: authTypes?.email,
        };

        try {
            const { data } = await otpAPI(formData);
            if (data?.statusCode === 200) {
                sweetAlert.success(t('email_verified'));
                getUserData();
                dispatch(loginAction({ ...storedUserData, emailVerified: true }));
                handleReset();
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            resetField('otp');
        }

        dispatch(hideLoader());
    }

    async function handleEmailResendOtp() {
        dispatch(showLoader());

        try {
            const { data } = await sendVerificationEmail({ email: userData?.email });
            if (data?.data?.key) {
                setValue('key', data?.data?.key);
                setValue('otp', '');
                sweetAlert.success(t('otp_sent'));
            } else {
                sweetAlert.error(t('something_went_wrong'));
            }
            setIsResendDisabled(true); // Disable the button
            setCountdown(30); // Reset countdown
            startCountdown(); // Start countdown again
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
            console.log(error);
        }

        dispatch(hideLoader());
    }

    const Completionist = () => <p className="resend-title">{t('enable_resend')}</p>;
    const renderer = ({ seconds, completed }) => {
        if (completed) return <Completionist />;
        else
            return (
                <p className="resend-title">
                    {t('resend_in')} <span>00:{seconds}</span>
                </p>
            );
    };

    function handleReset() {
        reset({
            ...watchAllFields,
            otp: '',
            key: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });
        setTogglePhoneOtpModal(false);
        setToggleEmailOtpModal(false);
        setShowPhoneChange(false);
        setShowPasswordChange(false);
        setShowEmailChange(false);
    }

    const startCountdown = () => {
        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount === 1) {
                    clearInterval(interval);
                    setIsResendDisabled(false); // Enable the button
                }
                return prevCount - 1;
            });
        }, 1000);
    };

    async function deleteAccountHandler() {
        dispatch(showLoader());
        try {
            await deleteAPI(storedUserData?.userId);
            setDeleteAccountModal(false);
            sweetAlert.success(t('successfullyDone'));
            dispatch(logoutAction());
            dispatch(clearMiniCartModal());
            navigate('/');
        } catch (error) {
            sweetAlert.error(error?.response?.data?.statusMessage || t('something_went_wrong'));
        }
        dispatch(hideLoader());
    }

    useEffect(() => {
        getCountriesList();
        startCountdown();
    }, []);

    useEffect(() => {
        getUserData();
    }, [storedUserData, countriesList]);

    useEffect(() => {
        if (
            storedUserData?.firstName !== watchAllFields?.firstName ||
            storedUserData?.lastName !== watchAllFields?.lastName
        ) {
            setShowEditButton(true);
        } else {
            setShowEditButton(false);
        }
    }, [watchAllFields, userData]);

    // console.log("watchAllFields", watchAllFields);

    return (
        <>
            <form onSubmit={handleSubmit(changeGeneralInfo)}>
                <div className="profile-list">
                    <ul>
                        <li>
                            <div className="grid">
                                <div className="col-12 md:col-3 lg:col-3">
                                    <div className="list-title">{t('general_info')}</div>
                                </div>

                                <div className="col-12 md:col-9 lg:col-9">
                                    <div className="list-content">
                                        <div className="flex flex-column gap-2 mb_15">
                                            <label
                                                htmlFor="firstName"
                                                className={classNames({
                                                    'p-error': errors?.firstName,
                                                })}>
                                                {t('first_name')}
                                            </label>
                                            <span className="form-input">
                                                <Controller
                                                    name="firstName"
                                                    control={control}
                                                    rules={{
                                                        required: t('this_field_is_required'),
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText
                                                            id={field.name}
                                                            {...field}
                                                            maxLength="100"
                                                            placeholder={t(
                                                                'first_name_placeholder',
                                                            )}
                                                            className={classNames({
                                                                'p-invalid': fieldState.error,
                                                            })}
                                                            value={field.value}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                            }}
                                                            keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                                        />
                                                    )}
                                                />
                                            </span>
                                            {getFormErrorMessage(errors?.['firstName'] || '')}
                                        </div>

                                        <div className="flex flex-column gap-2">
                                            <label
                                                htmlFor="lastName"
                                                className={classNames({
                                                    'p-error': errors?.lastName,
                                                })}>
                                                {t('last_name')}
                                            </label>
                                            <span className="form-input">
                                                <Controller
                                                    name="lastName"
                                                    control={control}
                                                    rules={{
                                                        required: t('this_field_is_required'),
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText
                                                            id={field.name}
                                                            {...field}
                                                            maxLength="100"
                                                            placeholder={t('last_name_placeholder')}
                                                            className={classNames({
                                                                'p-invalid': fieldState.error,
                                                            })}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                            }}
                                                            keyfilter={/^[a-zA-Z\u0600-\u06FF\s]+$/}
                                                        />
                                                    )}
                                                />
                                            </span>
                                            {getFormErrorMessage(errors?.['lastName'] || '')}
                                        </div>
                                    </div>

                                    {showEditButton && (
                                        <div className="edit-button flex align-items-center justify-content-start gap-3 mt-3">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    reset();
                                                }}
                                                className="second-btn">
                                                {t('cancel')}
                                            </button>

                                            <button
                                                type="submit"
                                                disabled={!isDirty || !isValid}
                                                className="main-btn">
                                                {t('save_changes')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </form>

            <div className="profile-list">
                <ul>
                    <li>
                        <div className="grid">
                            <div className="col-12 md:col-3 lg:col-3">
                                <div className="list-title">{t('security')}</div>
                            </div>

                            <div className="col-12 md:col-9 lg:col-9">
                                <div className="list-content">
                                    <div className="flex flex-column gap-2 mb-6">
                                        <label htmlFor="email">{t('email')}</label>
                                        <p className="flex align-items-center justify-content-between flex-wrap">
                                            <span className="flex align-items-center gap-3">
                                                {userData?.email}{' '}
                                                {!userData?.emailVerified ? (
                                                    <span
                                                        className="verify-color"
                                                        onClick={verifyEmailHandler}>
                                                        {t('verify_email')}
                                                    </span>
                                                ) : (
                                                    <span className="verified-color inline-flex align-items-center gap-1">
                                                        <Verified /> {t('verified')}
                                                    </span>
                                                )}
                                            </span>
                                            {/* <span className="verify-color" onClick={changeEmail} style={{ width: "300px" }}>
                                                {t('change_email')}
                                            </span> */}
                                        </p>
                                    </div>

                                    <div className="flex flex-column gap-2 mb-6">
                                        <label htmlFor="password">{t('password')}</label>
                                        <p className="flex align-items-center justify-content-between flex-wrap">
                                            <span className="flex align-items-center gap-3">
                                                {userData?.hasPassword
                                                    ? '******************'
                                                    : t('not_added')}
                                            </span>

                                            {userData?.hasPassword ? (
                                                <span
                                                    className="verify-color"
                                                    onClick={() => {
                                                        setShowPasswordChange(true);
                                                    }}
                                                    style={{ width: '300px' }}>
                                                    {t('change_password')}
                                                </span>
                                            ) : (
                                                <span
                                                    className="verify-color"
                                                    onClick={() => {
                                                        setShowPasswordChange(true);
                                                    }}
                                                    style={{ width: '300px' }}>
                                                    {t('add_password')}
                                                </span>
                                            )}
                                        </p>
                                    </div>

                                    <div className="flex flex-column gap-2 mb-6">
                                        <label htmlFor="phone">{t('phone')}</label>
                                        <p className="flex align-items-center justify-content-between flex-wrap">
                                            <span className="flex align-items-center gap-3">
                                                {userData?.phoneNumber
                                                    ? `${userData?.country?.phoneCode || ''} ${
                                                          userData?.phoneNumber
                                                      }`
                                                    : t('not_added')}
                                            </span>
                                            <span
                                                className="verify-color"
                                                onClick={() => setShowPhoneChange(true)}
                                                style={{ width: '300px' }}>
                                                {userData?.phoneNumber
                                                    ? t('change_phone')
                                                    : t('add_phone')}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="profile-list hidden">
                <ul>
                    <li>
                        <div className="grid">
                            <div className="col-12 md:col-3 lg:col-3">
                                <div className="list-title">{t('deleteAccount')}</div>
                            </div>

                            <div className="col-12 md:col-9 lg:col-9">
                                <div className="list-content">
                                    <button
                                        className="btn main-btn"
                                        onClick={() => setDeleteAccountModal(true)}>
                                        {t('deleteAccount')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            {/* delete account */}
            <Dialog
                header={<Back className="back-btn" onClick={() => setDeleteAccountModal(false)} />}
                visible={deleteAccountModal}
                draggable={false}
                resizable={false}
                onHide={() => setDeleteAccountModal(false)}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-center mb-3">
                        <h3>{t('deleteAccount')}</h3>
                    </div>
                    <div className="flex align-items-center justify-content-center gap-3">
                        <button
                            type="button"
                            onClick={deleteAccountHandler}
                            className="main-btn w-full">
                            {t('deleteAccount')}
                        </button>
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={showEmailChange}
                draggable={false}
                resizable={false}
                onHide={handleReset}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-center mb-3">
                        <h3 className="mb-0">{t('change_email')}</h3>
                    </div>
                    <form onSubmit={handleSubmit(changeEmail)}>
                        <div className="p-field">
                            <label
                                htmlFor="email"
                                className={classNames('p-d-block', { 'p-error': errors?.email })}>
                                {t('email')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="form-input">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{ required: t('this_field_is_required') }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            maxLength="100"
                                            placeholder={t('email_placeholder')}
                                            className={classNames({
                                                'p-invalid': fieldState.error,
                                            })}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors?.['email'] || '')}
                        </div>

                        <div className="flex align-items-center justify-content-center gap-3">
                            <button
                                type="submit"
                                disabled={!isDirty || !isValid}
                                className="main-btn w-full">
                                {t('save')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={showPasswordChange}
                onHide={handleReset}
                draggable={false}
                resizable={false}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-center mb-3">
                        {userData?.hasPassword ? (
                            <h3 className="mb-0">{t('change_password')}</h3>
                        ) : (
                            <h3 className="mb-0">{t('add_password')}</h3>
                        )}
                    </div>

                    <form onSubmit={handleSubmit(changePasswordHandler)}>
                        {(userData?.hasPassword && (
                            <div className="p-field">
                                <label
                                    htmlFor="oldPassword"
                                    className={classNames('p-d-block', {
                                        'p-error': errors?.oldPassword,
                                    })}>
                                    {t('old_password')}
                                    <span className="red-asterisk">*</span>
                                </label>
                                <span className="form-input">
                                    <Controller
                                        name="oldPassword"
                                        control={control}
                                        rules={{ required: t('this_field_is_required') }}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                id={field.name}
                                                {...field}
                                                maxLength="100"
                                                placeholder={t('old_password_placeholder')}
                                                className={classNames('password', {
                                                    'p-invalid': fieldState.error,
                                                })}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                                inputRef={field.ref}
                                                feedback={false}
                                                toggleMask
                                            />
                                        )}
                                    />
                                </span>
                                {getFormErrorMessage(errors?.['oldPassword'] || '')}
                            </div>
                        )) ||
                            ''}

                        <div className="p-field">
                            <label
                                htmlFor="newPassword"
                                className={classNames('p-d-block', {
                                    'p-error': errors?.newPassword,
                                })}>
                                {t('new_password')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="form-input">
                                <Controller
                                    name="newPassword"
                                    control={control}
                                    rules={{ required: t('this_field_is_required') }}
                                    render={({ field, fieldState }) => (
                                        <Password
                                            id={field.name}
                                            {...field}
                                            maxLength="100"
                                            placeholder={t('new_password_placeholder')}
                                            className={classNames('password', {
                                                'p-invalid': fieldState.error,
                                            })}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            inputRef={field.ref}
                                            feedback={false}
                                            toggleMask
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors?.['newPassword'] || '')}
                        </div>

                        <div className="p-field">
                            <label
                                htmlFor="confirmPassword"
                                className={classNames('p-d-block', {
                                    'p-error': errors?.confirmPassword,
                                })}>
                                {t('confirm_new_password')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <span className="form-input">
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={{ required: t('this_field_is_required') }}
                                    render={({ field, fieldState }) => (
                                        <Password
                                            id={field.name}
                                            {...field}
                                            maxLength="100"
                                            placeholder={t('confirm_new_password_placeholder')}
                                            className={classNames('password', {
                                                'p-invalid': fieldState.error,
                                            })}
                                            onChange={(e) => {
                                                field.onChange(e);
                                            }}
                                            inputRef={field.ref}
                                            feedback={false}
                                            toggleMask
                                        />
                                    )}
                                />
                            </span>
                            {getFormErrorMessage(errors?.['confirmPassword'] || '')}
                        </div>

                        <div className="flex align-items-center justify-content-center gap-3">
                            <button
                                type="submit"
                                disabled={!isDirty || !isValid}
                                className="main-btn w-full">
                                {t('save')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={showPhoneChange}
                onHide={handleReset}
                draggable={false}
                resizable={false}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-center mb-3">
                        {userData?.phoneNumber ? (
                            <h3 className="mb-0">{t('change_phone')}</h3>
                        ) : (
                            <h3 className="mb-0">{t('add_phone')}</h3>
                        )}
                    </div>

                    <form onSubmit={handleSubmit(changePhoneNumber)}>
                        <div className="phone-num field">
                            <label
                                htmlFor="country"
                                className={classNames({ 'p-error': errors.country })}>
                                {t('phone_number')}
                                <span className="red-asterisk">*</span>
                            </label>
                            <div className="phone-fields">
                                <div className="field">
                                    <Controller
                                        name="country"
                                        control={control}
                                        rules={{ required: t('this_field_is_required') }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                value={field.value || userData?.country || ''}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    setValue('phone', '');
                                                }}
                                                options={countriesList?.map((country) => ({
                                                    id: country?.id || '',
                                                    name: country?.phoneCode || '',
                                                    code: country?.twoLetterCode || '',
                                                    hintText: country?.hintText || '',
                                                    phoneLength: country?.phoneLength,
                                                }))}
                                                placeholder={t('country_code')}
                                                optionLabel="code"
                                                filter
                                                valueTemplate={selectedCountryTemplate}
                                                itemTemplate={countryOptionTemplate}
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage('phoneCode')}
                                </div>

                                <div className="field w-full">
                                    <span className="phone_num">
                                        <Controller
                                            name="phone"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: {
                                                    value: watchAllFields?.country?.phoneLength,
                                                    message: t('phone_number_min_length', {
                                                        length:
                                                            watchAllFields?.country?.phoneLength ||
                                                            0,
                                                    }),
                                                },
                                                maxLength: {
                                                    value: watchAllFields?.country?.phoneLength,
                                                    message: t('phone_number_max_length', {
                                                        length:
                                                            watchAllFields?.country?.phoneLength ||
                                                            15,
                                                    }),
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    id={field.name}
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    className={classNames('w-full', {
                                                        'p-invalid': fieldState.error,
                                                        'phone-number-input': true,
                                                    })}
                                                    placeholder={
                                                        watchAllFields?.country?.hintText ||
                                                        t('phone_number_placeholder')
                                                    }
                                                    maxLength={watchAllFields?.country?.phoneLength}
                                                    keyfilter="pint"
                                                />
                                            )}
                                        />
                                    </span>
                                    {getFormErrorMessage('phone')}
                                </div>
                            </div>
                        </div>

                        <div className="flex align-items-center justify-content-center gap-3">
                            <button
                                type="submit"
                                disabled={!isDirty || !isValid}
                                className="main-btn w-full">
                                {t('save')}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={togglePhoneOtpModal}
                onHide={handleReset}
                draggable={false}
                resizable={false}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h3>{t('verify_phone_number')}</h3>
                    </div>

                    <div className="form-bx">
                        <div className="flex flex-column">
                            <div className="otp-num">
                                <div className="field">
                                    <span className="p-float-label">
                                        <Controller
                                            name="otp"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: { value: 4 },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <OTPInput
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    numInputs={4}
                                                    minLength={0}
                                                    renderSeparator={<span>-</span>}
                                                    renderInput={(props) => (
                                                        <InputText keyfilter="int" {...props} />
                                                    )}
                                                />
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>

                            <Button
                                className="btn"
                                label={t('submit')}
                                disabled={!isDirty || !isValid}
                                onClick={handlePhoneOTPSubmit}
                            />
                        </div>

                        <div className="my-3 text-center">
                            <Countdown date={Date.now() + countdown * 1000} renderer={renderer} />
                            <button
                                onClick={handlePhoneResendOtp}
                                className="text-btn mt-3"
                                disabled={isResendDisabled}>
                                {t('resend')}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                header={<Back className="back-btn" onClick={handleReset} />}
                visible={toggleEmailOtpModal}
                onHide={handleReset}
                draggable={false}
                resizable={false}
                style={{ width: '35vw' }}>
                <div className="confirm-popup-body">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <h3>{t('verify_email')}</h3>
                    </div>

                    <div className="form-bx">
                        <div className="flex flex-column">
                            <div className="otp-num">
                                <div className="field">
                                    <span className="p-float-label">
                                        <Controller
                                            name="otp"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: { value: 4 },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <OTPInput
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                    }}
                                                    numInputs={4}
                                                    minLength={0}
                                                    renderSeparator={<span>-</span>}
                                                    renderInput={(props) => (
                                                        <InputText keyfilter="int" {...props} />
                                                    )}
                                                />
                                            )}
                                        />
                                    </span>
                                </div>
                            </div>

                            <Button
                                className="btn"
                                label={t('submit')}
                                disabled={!isDirty || !isValid}
                                onClick={handleEmailOTPSubmit}
                            />
                        </div>

                        <div className="my-3 text-center">
                            <Countdown date={Date.now() + countdown * 1000} renderer={renderer} />
                            <button
                                onClick={handleEmailResendOtp}
                                className="text-btn mt-3"
                                disabled={isResendDisabled}>
                                {t('resend')}
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default MyProfile;
