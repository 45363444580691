import { createSlice } from '@reduxjs/toolkit';
import { getAddToCart } from '../api/shopingCartAPIs';

export const addToCartSlice = createSlice({
    name: 'cartButton',
    initialState: {
        addToCart: [],
        responseData: {},
        totalCount: 0,
        totalCartPrice: 0,
        loading: false,
        showMiniCartModal: false,
    },
    extraReducers: {
        [getAddToCart?.pending]: (state) => {
            console.log('pending');
            state.loading = true;
        },
        [getAddToCart?.fulfilled]: (state, action) => {
            console.log('fulfilled', action.payload);
            state.responseData = action.payload;
            if (action.payload?.data) {
                state.totalCount = action.payload.totalCount;
                state.totalCartPrice = action.payload.totalPrice;
                state.showMiniCartModal = true;
            }
            state.loading = false;
        },
        [getAddToCart?.rejected]: (state) => {
            console.log('rejected');
            state.loading = false;
            state.showMiniCartModal = false;
        },
    },
    reducers: {
        updateCartIcon: (state, action) => {
            state.totalCount = action.payload.totalCount;
            state.totalCartPrice = action.payload.totalCartPrice;
        },
        setShowMiniCartModal: (state, action) => {
            state.showMiniCartModal = action.payload;
        },
        clearMiniCartModal: (state) => {
            state.responseData = {};
            state.totalCount = 0;
            state.totalCartPrice = 0;
            state.loading = false;
            state.showMiniCartModal = false;
        },
    },
});

export const { setShowMiniCartModal, clearMiniCartModal, updateCartIcon } = addToCartSlice.actions;
export default addToCartSlice.reducer;
