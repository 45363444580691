import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loginAPI } from '../../api/authAPIs';
import { getAuthProviders, getCountries } from '../../api/commonAPIs';
import { getMyCart } from '../../api/shopingCartAPIs';
import '../../assets/css/pages/auth.css';
import countryOptionTemplate from '../../components/countryOptions/countryOptions';
import selectedCountryTemplate from '../../components/selectedCountry/selectedCountry';
import sweetAlert from '../../helpers/sweetAlert';
import { useSocialLogin } from '../../hooks/useSocialLogin';
import { updateCartIcon } from '../../redux/addToCart';
import { loginAction } from '../../redux/authSlice';
import { hideLoader, showLoader } from '../../redux/loaderSlice';
import { authTypes } from '../../utils/constants';
import { addWishListFromLogin } from '../../api/wishListAPI';
import { clearMyWishList } from '../../redux/wishListSlice';

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socialLogin = useSocialLogin();
    const [countriesList, setCountreisList] = useState([]);
    const [activeSocialLoginProviders, setActiveSocialLoginProviders] = useState([]);
    const [toggleSignin, setToggleSignin] = useState(true);
    const wishList = localStorage.getItem('wishlist');
    const compareList = localStorage.getItem('compareList');

    const {
        control,
        formState: { errors, isDirty, isValid },
        watch,
        reset,
        resetField,
        handleSubmit,
    } = useForm({ mode: 'onChange' });

    const watchedEmail = watch('email');
    const watchedPhone = watch('phoneNumber');
    const watchedCountryCode = watch('countryCode');

    async function getCountriesList() {
        dispatch(showLoader());

        try {
            const res = await getCountries(true);
            setCountreisList(res.data.data);
            reset({
                countryCode: {
                    id: res.data.data[0]?.id,
                    name: res.data.data[0]?.phoneCode,
                    code: res.data.data[0]?.twoLetterCode,
                    hintText: res.data.data[0]?.hintText,
                    phoneLength: res.data.data[0]?.phoneLength,
                },
            });
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    async function getActiveSocialLoginProviders() {
        dispatch(showLoader());

        try {
            const { data } = await getAuthProviders();
            setActiveSocialLoginProviders(data?.data?.filter((provider) => provider?.enabled));
        } catch (error) {
            console.log(error);
        }

        dispatch(hideLoader());
    }

    const onSubmit = (data) => {
        dispatch(showLoader());

        let loginData = {
            email: data?.email || '',
            password: data?.password || '',
            phoneNumber: data?.phoneNumber || '',
            countryId: toggleSignin ? '' : data?.countryCode?.id || '',
            authType: toggleSignin ? authTypes?.email : authTypes?.phone,
        };

        if (toggleSignin) {
            delete loginData?.phoneNumber;
            delete loginData?.countryId;
        } else {
            delete loginData?.email;
        }
        loginAPI(loginData)
            .then((res) => {
                // if (wishList) {
                //     let idArray = wishList?.split(',')?.map((item) => item.trim());
                //     if (idArray.length > 0) { for (const id of idArray) { dispatch(getAddToWishList({ productId: id, })) } }
                // }

                // if (compareList) {
                //     let idArray = compareList?.split(',')?.map((item) => item.trim());
                //     if (idArray.length > 0) { for (const id of idArray) { dispatch(getAddToCompareList({ productId: id, })) } }
                // }

                sweetAlert.success(t('login_success'));
                dispatch(loginAction(res?.data?.data));
                getMyCart({ PageNumber: 0, PageSize: 20 }).then((res) => {
                    dispatch(
                        updateCartIcon({
                            totalCount: res?.data?.data?.priceDetails?.totalQuantity || 0,
                            totalCartPrice: res?.data?.data?.priceDetails?.total || 0,
                        }),
                    );
                });
                addWishListFromLogin(wishList)
                    .then((res) => {
                        console.log(res, 'res');
                        localStorage.removeItem('wishlist');
                        dispatch(clearMyWishList());
                    })
                    .catch((err) => {
                        console.log(err, 'err wishlist');
                    });
                navigate('/');
            })
            .catch((error) => {
                const errorList = error.response?.data?.statusMessage || ['server error'];
                if (errorList?.includes('validate')) {
                    navigate(`/verify-email?Key=${error?.response?.data?.data?.key}`);
                    Swal.fire({
                        icon: 'warning',
                        title: errorList,
                        confirmButtonText: t('ok'),
                    });
                    return;
                }
                console.log(error, errorList);
                if (errorList?.includes('Unauthorized') && error.response?.data?.data?.userId) {
                    navigate(`/otp?Key=${error?.response?.data?.data?.key}`);
                    Swal.fire({
                        icon: 'warning',
                        title: t('please_verify_your_phone_number'),
                        confirmButtonText: t('ok'),
                    });
                    return;
                }
                Swal.fire({
                    icon: 'error',
                    title: errorList,
                    confirmButtonText: t('close'),
                });
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    function toggleSigninMethod() {
        if (watchedEmail) {
            if (watchedEmail?.match(/^[0-9]+$/) && watchedEmail?.length > 0) {
                setToggleSignin(false);
                resetField('email');
            } else {
                setToggleSignin(true);
                resetField('phoneNumber');
            }
        } else if (watchedPhone?.length === 0) {
            setToggleSignin(true);
            resetField('phoneNumber');
        }
    }

    useEffect(() => {
        getActiveSocialLoginProviders();
        getCountriesList();
    }, []);

    useEffect(() => {
        toggleSigninMethod();
    }, [watchedEmail, watchedPhone]);

    useEffect(() => {
        if (toggleSignin) {
            document.getElementById('email')?.focus();
        } else {
            document.getElementById('phoneNumber')?.focus();
        }
    }, [toggleSignin]);

    return (
        <div className="auth-bx">
            <div className="title">
                <h3>{t('Sign_in_to_your_account')}</h3>
            </div>

            <div className="form-bx">
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
                    {toggleSignin ? (
                        <div className="field">
                            <label
                                htmlFor="email"
                                className={classNames({ 'p-error': errors.email })}>
                                <span className="red-asterisk">*</span> {t('email_or_phone_number')}
                            </label>
                            <span className="p-float-label">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: t('this_field_is_required'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('invalid_email_address'),
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={classNames({
                                                    'p-invalid': fieldState.error,
                                                    'email-input': true,
                                                })}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value);
                                                }}
                                                autoComplete="off"
                                            />
                                        </>
                                    )}
                                />
                            </span>
                            {getFormErrorMessage('email')}
                        </div>
                    ) : (
                        <div className="phone-num field">
                            <label
                                htmlFor="countryCode"
                                className={classNames({ 'p-error': errors.countryCode })}>
                                <span className="red-asterisk">*</span> {t('phone_number_or_email')}
                            </label>
                            <div className="phone-fields">
                                <div className="field">
                                    <Controller
                                        name="countryCode"
                                        control={control}
                                        rules={{
                                            required: t('this_field_is_required'),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                value={field.value || ''}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    resetField('phoneNumber');
                                                }}
                                                options={countriesList.map((country) => ({
                                                    id: country?.id,
                                                    name: country?.phoneCode,
                                                    code: country?.twoLetterCode,
                                                    hintText: country?.hintText,
                                                    phoneLength: country?.phoneLength,
                                                }))}
                                                placeholder={t('country_code')}
                                                optionLabel="code"
                                                filter
                                                valueTemplate={selectedCountryTemplate}
                                                itemTemplate={countryOptionTemplate}
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage('phoneCode')}
                                </div>

                                <div className="field w-full">
                                    <span className="phone_num">
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            rules={{
                                                required: t('this_field_is_required'),
                                                minLength: {
                                                    value: watchedCountryCode?.phoneLength,
                                                    message: t('phone_number_min_length', {
                                                        length:
                                                            watchedCountryCode?.phoneLength || 0,
                                                    }),
                                                },
                                                maxLength: {
                                                    value: watchedCountryCode?.phoneLength,
                                                    message: t('phone_number_max_length', {
                                                        length:
                                                            watchedCountryCode?.phoneLength || 15,
                                                    }),
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    id={field.name}
                                                    value={field.value || ''}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value);
                                                    }}
                                                    className={classNames({
                                                        'p-invalid': fieldState.error,
                                                        'phone-number-input': true,
                                                    })}
                                                    placeholder={
                                                        watchedCountryCode?.hintText ||
                                                        t('phone_number_placeholder')
                                                    }
                                                    maxLength={watchedCountryCode?.phoneLength}
                                                    keyfilter="pint"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </span>
                                    {getFormErrorMessage('phoneNumber')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="field">
                        <label
                            htmlFor="password"
                            className={classNames({ 'p-error': errors.email })}>
                            <span className="red-asterisk">*</span> {t('password')}
                        </label>
                        <span className="p-float-label">
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: t('this_field_is_required') }}
                                render={({ field, fieldState }) => (
                                    <Password
                                        id={field.name}
                                        {...field}
                                        inputRef={field.ref}
                                        className={classNames({ 'p-invalid': fieldState.error })}
                                        feedback={false}
                                        toggleMask
                                        autoComplete="off"
                                    />
                                )}
                            />
                        </span>
                        {getFormErrorMessage('password')}

                        <div className="forgot">
                            <Link
                                to="/forget-password"
                                onClick={() => {
                                    window.scroll({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}>
                                {t('forget_password')}
                            </Link>
                        </div>
                    </div>

                    <Button
                        className="btn"
                        type="submit"
                        label={t('sign_in')}
                        disabled={!isDirty || !isValid}
                        onClick={() => {
                            window.scroll({ top: 0, behavior: 'smooth' });
                        }}
                    />
                </form>

                <div className="social-bx">
                    {activeSocialLoginProviders?.length ? (
                        <>
                            <div className="title">{t('or_signin_by')}</div>
                            <div className="icons">
                                {activeSocialLoginProviders?.map((provider) => (
                                    <button
                                        key={provider?.id}
                                        onClick={() => {
                                            socialLogin(provider?.title, provider?.type);
                                        }}>
                                        <img src={provider?.icon} alt={provider?.title} />
                                    </button>
                                ))}
                            </div>
                        </>
                    ) : null}
                    <div className="text">
                        {t('dont_have_account')}
                        <Link
                            to="/register"
                            onClick={() => {
                                window.scroll({ top: 0, behavior: 'smooth' });
                            }}>
                            {t('sign_up')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
