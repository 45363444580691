import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Back } from '../../assets/images/back.svg';
import { ReactComponent as Star } from '../../assets/images/star.svg';
import { ReactComponent as StarReview } from '../../assets/images/star-review.svg';
import { ReactComponent as Emoji1 } from '../../assets/images/emojis/emoji-1.svg';
import { ReactComponent as Emoji2 } from '../../assets/images/emojis/emoji-2.svg';
import { ReactComponent as Emoji3 } from '../../assets/images/emojis/emoji-3.svg';
import { ReactComponent as Emoji4 } from '../../assets/images/emojis/emoji-4.svg';
import { ReactComponent as Emoji5 } from '../../assets/images/emojis/emoji-5.svg';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Rating } from 'react-simple-star-rating';
import LoadingButton from '../LoadingButton/LoadingButton';
import { useDispatch } from 'react-redux';
import { hideMiniLoader, showMiniLoader } from '../../redux/loaderSlice';
import { addProductReviewAPI } from '../../api/productAPIs';
import sweetAlert from '../../helpers/sweetAlert';
import { Tooltip } from 'primereact/tooltip';

export default function ReviewProductModal({
    toggleReviewModal,
    setToggleReviewModal = () => {},
    product = {},
    forceRerender = () => {},
}) {
    const { t, i18n } = useTranslation();
    const modalRef = useRef(null);
    const dispatch = useDispatch();

    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');

    // Catch Emoji value
    const handleEmoji = () => {
        switch (rating) {
            case 1:
                return <Emoji1 />;
            case 2:
                return <Emoji2 />;
            case 3:
                return <Emoji3 />;
            case 4:
                return <Emoji4 />;
            case 5:
                return <Emoji5 />;
            default:
                return '';
        }
    };

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate);

        // other logic
    };

    function ReviewHeader() {
        return (
            <div className="flex align-items-center justify-content-between gap-3">
                <div className="product-image__wrapper">
                    <img src={product?.imageURL} alt={product?.name} />
                </div>
                <Tooltip
                    target={`.product_name__title-${product.id}`}
                    position="top"
                    style={{ maxWidth: '300px' }}
                    content={product.name}
                />
                <h2 className={`product-title product_name__title-${product.id}`}>
                    {product?.name}
                </h2>
            </div>
        );
    }

    function handleReset() {
        setToggleReviewModal(false);
        setRating(0);
        setReviewText('');
    }

    async function handelReviewSubmit() {
        if (rating === 0 && reviewText?.length === 0 && !product?.id) {
            return;
        }
        dispatch(showMiniLoader());
        // API call
        try {
            const { data } = await addProductReviewAPI({
                productId: product?.id,
                rating: rating,
                reviewText: reviewText,
            });
            console.log(data);
            sweetAlert.success(t(`review_added_successfully`));
            handleReset();
            setTimeout(() => {
                forceRerender();
            }, 200);
        } catch (error) {
            console.log(error);
            sweetAlert.error(error?.response?.data?.statusMessage ?? t(`something_went_wrong`));
        }
        dispatch(hideMiniLoader());
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                handleReset();
            }
        });
        return () => {
            document.removeEventListener('click', (e) => {
                if (modalRef.current && !modalRef.current.contains(e.target)) {
                    handleReset();
                }
            });
        };
    }, []);
    return (
        <>
            <Dialog
                className="review-product-modal"
                header={<ReviewHeader />}
                visible={toggleReviewModal}
                onHide={handleReset}
                style={{ width: '65vw', maxWidth: '450px' }}
                draggable={false}
                resizable={false}>
                <div ref={modalRef} className="confirm-popup-body mt-4">
                    <div className="form-bx">
                        <div className="flex flex-column">
                            <div className="review-star">
                                <div className="field">
                                    <span className="main-text text-center w-full inline-block">
                                        {t(`rate-text`)}
                                    </span>
                                </div>
                                <div className="review-star__wrapper flex flex-column gap-2 align-items-center">
                                    <div className="flex align-items-center justify-content-center">
                                        {handleEmoji()}
                                    </div>
                                    <Rating
                                        onClick={handleRating}
                                        rtl={i18n?.language === 'ar' ? true : false}
                                        emptyIcon={<Star />}
                                        fillIcon={<StarReview />}

                                        /* Available Props */
                                    />
                                </div>
                            </div>

                            <h2 className="text-center my-4 main-text">
                                {t(`write_product_review`)}
                            </h2>

                            <div className="product_review">
                                <h3 className="main-text">{t(`add_written_review`)}</h3>
                                <textarea
                                    placeholder={t('add_review')}
                                    className="form-control"
                                    rows="5"
                                    value={reviewText}
                                    maxLength={300}
                                    onChange={(e) => setReviewText(e.target.value)}
                                    required></textarea>
                            </div>

                            <LoadingButton
                                btnText={t(`submit`)}
                                btnDisabled={
                                    rating > 0 ? false : reviewText?.length > 0 ? false : true
                                }
                                btnClick={handelReviewSubmit}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
