import { createAsyncThunk } from '@reduxjs/toolkit';
import sweetAlert from '../helpers/sweetAlert';
import { baseInstance } from './baseInstance';

export const getAddToCart = createAsyncThunk('/product/AddToCart', async (body) => {
    try {
        const res = await baseInstance.post(
            `/ShoppingCart/AddProductToCartFromDetailsCustom`,
            body,
        );
        const myCart = await baseInstance.get('/ShoppingCart/Cart');
        console.log(myCart, 'myCart');
        let totalItemsPrice = 0;
        myCart?.data?.items?.forEach((item) => {
            totalItemsPrice += item.subTotalValue;
        });
        return {
            ...res.data,
            totalCount: myCart.data.items?.length || 0,
            totalPrice: totalItemsPrice,
        };
    } catch (error) {
        console.log(error);
        if (error.response.data.statusMessage) {
            sweetAlert.error(error.response.data.statusMessage);
        }
    }
});

export const getMyCart = (data) => {
    return baseInstance.post('/ShoppingCart/MyCart', data);
};

export const updateCartQuantity = (data) => {
    return baseInstance.post('/ShoppingCart/UpdateCartQuantity', data);
};

export const applyCustomCoupon = (data) => {
    return baseInstance.post(
        `/ShoppingCart/ApplyCustomDiscountCoupon?discountCouponCode=${data.discountCouponCode}`,
    );
};

export const getMyMiniCart = () => {
    return baseInstance.get('/ShoppingCart/MiniCart');
};
