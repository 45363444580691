import React, { useEffect, useState } from 'react';
import { getPaymentMethods, checkoutPay } from '../../api/checkoutAPIs';
import { getMyCart } from '../../api/shopingCartAPIs';
import { getAddressDetails } from '../../api/CustomerAPIs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearMiniCartModal } from '../../redux/addToCart';
import NoData from '../../components/NoData/NoData';
import Pagination from '../../components/Pagination/Pagination';
import ItemsList from './ItemsList';
import PaymentList from './PaymentList';
import PriceCard from '../Cart/PriceCard';
import sweetAlert from '../../helpers/sweetAlert';
import './Checkout.css';
import { hideLoader, hideMiniLoader, showLoader, showMiniLoader } from '../../redux/loaderSlice';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import HyperPayCard from './HyperPayCard';

export default function Checkout() {
    const { couponId, addressId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [cartPrice, setCartPrice] = useState({});
    const [couponCode, setCouponCode] = useState('');
    const [address, setAddress] = useState('');
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [visaCardId, setVisaCardId] = useState('');
    const [cartPage, setCartPage] = useState(0);
    const [cvv, setCvv] = useState('');
    const [cartPageSize] = useState(20);
    const [countryId, setCountryId] = useState('');

    async function getMyCartData() {
        dispatch(showLoader());
        const formData = {
            PageNumber: cartPage,
            PageSize: cartPageSize,
        };
        try {
            const { data } = await getMyCart(formData);
            setCartData(data?.data);
            setCartPrice(data?.data?.priceDetails);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    async function getPaymentMethodsData() {
        dispatch(showLoader());
        try {
            const { data } = await getPaymentMethods();
            setPaymentMethods(data?.data);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    function handleParams() {
        const address = addressId?.split('=')?.[1];
        const couponCode = couponId?.split('=')?.[1];
        setCouponCode(couponCode);
        setAddress(address);
        getAddressCountry(address);
    }

    async function getAddressCountry(shippingAddressId) {
        dispatch(showLoader());
        try {
            const { data } = await getAddressDetails(shippingAddressId);
            setCountryId(data?.data?.country?.id);
        } catch (error) {
            console.log(error);
        }
        dispatch(hideLoader());
    }

    function handlePayByHyperPay(checkoutId, orderId) {
        console.log('Loading ');
        try {
            window.wpwlOptions = {
                style: 'card',
                locale: i18n.language,
                saveCard: false,
                iframeStyles: {
                    'card-number-placeholder': {
                        'text-align': i18n.language === 'ar' ? 'right' : 'left',
                        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                    },
                    'expiry-date-placeholder': {
                        'text-align': i18n.language === 'ar' ? 'right' : 'left',
                        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                    },
                },
                onReady: function (event) {
                    console.log('onReady', event);
                    const cardNumber = document.querySelector('.wpwl-wrapper-cardNumber');
                    cardNumber.style.direction = 'ltr';
                    const expiryDate = document.querySelector('.wpwl-control-expiry');
                    expiryDate.style.textAlign = i18n.language === 'ar' ? 'right' : 'left';
                },
                onLoad: function (event) {
                    console.log('onLoad', event);
                },
                onError: function (event) {
                    console.log('onError', event);
                },
                onBeforeSubmit: function (event) {
                    console.log('onBeforeSubmit', event);
                },
                onSuccess: function (event) {
                    console.log('onSuccess', event);
                },
                onReadyToSubmit: function (event) {
                    console.log('onReadyToSubmit', event);
                },
                onFieldError: function (event) {
                    console.log('onFieldError', event);
                },
                onBrand: function (event) {
                    console.log('onBrand', event);
                },
                onBinLookup: function (event) {
                    console.log('onBinLookup', event);
                },
                onValidation: function (event) {
                    console.log('onValidation', event);
                },
                onRisk: function (event) {
                    console.log('onRisk', event);
                },
                on3DSecure: function (event) {
                    console.log('on3DSecure', event);
                },
                onRedirect: function (event) {
                    console.log('onRedirect', event);
                },
                onAbort: function (event) {
                    console.log('onAbort', event);
                },
            };
            const script = document.createElement('script');
            const hyperPayWrapper = document.getElementById('paymentWidgetsHyper');
            const hyperPayBody = document.getElementById('paymentWidgetsHyperBody');

            script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
            script.async = true;
            script.id = 'paymentWidgetsHyperScriptTag';
            script.onload = () => {
                if (hyperPayWrapper) {
                    hyperPayWrapper.classList.remove('hidden');
                }
            };

            document.body.appendChild(script);

            const form = document.createElement('form');
            form.action = `/checkout/payment-result?paymentMethodId=${paymentMethodId}&orderId=${orderId}`;
            form.setAttribute('class', 'paymentWidgets');
            form.setAttribute('data-brands', `${paymentMethodId === 16 ? 'MADA' : 'VISA MASTER'}`); // 'VISA MASTER'
            // document.body.appendChild(form);
            if (hyperPayBody) {
                hyperPayBody.appendChild(form);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function handleCheckoutPay() {
        dispatch(showMiniLoader());
        // console.log(visaCardId, 'visaCardId');
        const formData = {
            coupon: couponCode || '',
            addressId: +address || null,
            countryId: +countryId || null,
            paymentMethodId: +paymentMethodId || null,
        };

        try {
            const { data } = await checkoutPay(formData);
            console.log('data', data);

            if ((paymentMethodId == 15 || paymentMethodId == 16) && data?.data?.checkOutId) {
                handlePayByHyperPay(data?.data?.checkOutId, data?.data?.orderId);
            } else {
                sweetAlert.success(t('success'));
                dispatch(clearMiniCartModal());
                navigate(`/profile/orders/${data?.data?.orderId}`);
            }
        } catch (error) {
            console.log(error);
            sweetAlert.error(
                error?.response?.data?.statusMessage ||
                    error?.response?.data ||
                    t('somethingWentWrong'),
            );
        }
        dispatch(hideMiniLoader());
    }

    useEffect(() => {
        getMyCartData();
        getPaymentMethodsData();
        handleParams();

        return () => {
            setCartData([]);
            setPaymentMethods([]);
        };
    }, [couponId, addressId]);

    useEffect(() => {
        getMyCartData();
    }, [cartPage]);

    if (!cartData || !cartData?.products?.length)
        return <NoData productShape={true} text={t('no_items')} />;

    return (
        <section className="container_bx cart-section mt-5">
            <div className="grid">
                <div className="col-12 lg:col-8">
                    <ItemsList list={cartData?.products} />
                    {(cartData?.paging?.TotalItems > cartPageSize && (
                        <Pagination
                            totalItems={cartData?.paging?.TotalItems || 0}
                            itemsPerPage={cartPageSize || 20}
                            currentPage={cartPage || 0}
                            onPageChange={(page) => setCartPage(page)}
                        />
                    )) ||
                        ''}
                    <PaymentList
                        list={paymentMethods}
                        paymentMethodId={paymentMethodId}
                        setPaymentMethodId={setPaymentMethodId}
                        visaCardId={visaCardId}
                        setVisaCardId={setVisaCardId}
                        cvv={cvv}
                        setCvv={setCvv}
                    />
                </div>

                <div className="col-12 lg:col-4">
                    <div className="checkout-sidebar">
                        <PriceCard
                            prices={cartPrice}
                            setPrices={setCartPrice}
                            couponCode={couponCode}
                            setCouponCode={setCouponCode}
                        />

                        <LoadingButton
                            btnClick={handleCheckoutPay}
                            btnText={t('placeOrder')}
                            btnDisabled={
                                !address || !paymentMethodId
                                // (paymentMethodId == 15 && !visaCardId) ||
                                // (paymentMethodId == 15 && !cvv)
                            }
                        />
                    </div>
                </div>
            </div>
            {(paymentMethodId == 15 || paymentMethodId == 16) && <HyperPayCard />}
        </section>
    );
}
